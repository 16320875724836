import React from 'react'
import '../../styles/themes/mazda/index.scss'

/**
 * The sole purpose of this file is to indicate to Gatsby and the "gatsby-plugin-split-css" package
 * to generate a separate production CSS file from "styles/themes/mazda/index.scss" which will look
 * something like "public/component---src-pages-themes-mazda-js.ff70270e3a44135bf369.css"
 *
 * This CSS file is then picked up by the "onPostBuild" hook in "gatsby-node.js" during the
 * production build process and copied over to "public/static/theme-mazda.css", for instance.
 */

const MazdaPage = () => <></>

export default MazdaPage
